export type CSSStylesType = ReadonlyArray<{ name: string; value: string | null }>;

export function scrollBarWidth(): string {
  const width =
    window.innerWidth ||
    (document.documentElement && document.documentElement.clientWidth) ||
    (document.body && document.body.clientWidth);
  return `${(width || 0) - ((document.body && document.body.offsetWidth) || 0)}px`;
}

export function updateStyle<T extends HTMLElement>(el: T | null, val: CSSStylesType) {
  if (el) {
    val.forEach((prop) => {
      el.style.setProperty(prop.name, prop.value);
    });
  }
}

export function setStyle<T extends Element>(el: T | null, value: string) {
  if (el) {
    el.setAttribute('style', value);
  }
}

export function setStyleList<T extends Element>(el: T | null, val: CSSStylesType) {
  if (el) {
    const props = val.map(({ name, value }) => `${name}: ${value}`).join(';');
    setStyle(el, props);
  }
}

export function bodyToggle(isOpened: boolean) {
  const { body } = document;
  if (body) {
    if (!isOpened) {
      updateStyle(body, [
        { name: 'overflow', value: 'hidden' },
        { name: 'padding-right', value: scrollBarWidth() },
      ]);
    } else {
      updateStyle(body, [
        { name: 'overflow', value: null },
        { name: 'padding-right', value: null },
      ]);
    }
  }
}

export function isTouchEnabled(): boolean {
  return 'ontouchstart' in global || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}
