import * as React from 'react';
import clsx from 'clsx';
import { setStyleList } from 'lib/domHelper';
import styles from './tabs.module.css';
import Stepper from 'components/services/devops/components/Stepper';
interface Props {
  list?: ReadonlyArray<{
    title: string;
  }>;
  children: React.ReactNode;
  isArrow?: boolean;
  isDevOpsPage?: boolean;
  btnClassName: string;
  activeClassName?: string;
  onChange?: (idx: number) => void;
}

// TODO: remove isDevOpsPage and split in new component
function Tabs({ list, children, onChange, isDevOpsPage, ...rest }: Props) {
  const [active, setActive] = React.useState<number>(0);

  const container = React.useRef<null | HTMLUListElement>(null);

  const handleClick = React.useCallback(
    (idx: number) => {
      if (container.current && container.current.children) {
        setStyleList(container.current.children.item(active), [
          { name: 'opacity', value: '0' },
          { name: 'position', value: 'absolute' },
          { name: 'transform', value: 'translate3d(100%, 0, 0)' },
        ]);
        setStyleList(container.current.children.item(idx), [
          { name: 'opacity', value: '1' },
          { name: 'position', value: 'relative' },
          { name: 'transform', value: 'translate3d(0%, 0, 0)' },
        ]);
      }
      if (typeof onChange === 'function') onChange(idx);
      setTimeout(() => {
        setActive(idx);
      }, 300);
    },
    [active]
  );

  React.useEffect(() => {
    if (container.current) {
      for (let i = 0; i < container.current.children.length; i++) {
        if (i !== 0) {
          setStyleList(container.current.children.item(i), [
            { name: 'opacity', value: '0' },
            { name: 'position', value: 'absolute' },
            { name: 'transform', value: 'translate3d(100%, 0, 0)' },
          ]);
        }
      }
    }
  }, []);

  return (
    <div className={styles.container}>
      {isDevOpsPage ? (
        <Stepper onClick={handleClick} />
      ) : (
        <ul className={styles.list}>
          {!!list &&
            list.map((e, i) => (
              <li className={styles.item} key={e.title}>
                <button
                  className={clsx(styles.btn, rest.btnClassName, {
                    [styles.active]: active === i,
                    [rest?.activeClassName || rest.btnClassName]: active === i,
                    [styles.btn_arrow]: i !== list.length - 1 && rest.isArrow,
                  })}
                  onClick={() => handleClick(i)}
                >
                  {e.title}
                </button>
              </li>
            ))}
        </ul>
      )}
      <ul ref={container} className={styles.content}>
        {children}
      </ul>
    </div>
  );
}

export default Tabs;
