import React, { FC, useCallback, useState } from 'react';
import loadable from '@loadable/component';

export enum Step {
  plan,
  code,
  build,
  test,
  release,
  deploy,
  operate,
  monitor = 6,
}

interface IProps {
  onClick?: (step: number) => void;
}

const StepperView = loadable(() => import('../../assets/stepper'));
const MobileStepper = loadable(() => import('../../assets/mobileStepper'));

const Stepper: FC<IProps> = ({ onClick }) => {
  const [step, setStep] = useState(Step.plan);

  const handleChangeStep = useCallback(
    (data: any) => {
      setStep(data.id);
      if (onClick) onClick(data.id);
    },
    [onClick]
  );

  return (
    <>
      <StepperView currentStep={step} onClick={handleChangeStep} />
      <MobileStepper currentStep={step} onClick={handleChangeStep} />
    </>
  );
};

export default Stepper;
